import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import * as queryString from "query-string"
import AnchorLink from "react-anchor-link-smooth-scroll"

import SEO from "../components/SEO"
import styles from "../css/product-css-modules.module.css"
import Banner from "../components/Banner"
import Button from "../components/Button"
import Icon from "../components/Icon"
import Layout from "../components/layout/Layout"
import Form from "../components/Form"
import { 
    Row, 
    ResponsiveRow, 
    Col, 
    SectionWrapper, 
    SectionWrapperGray,
    SectionInnerWrapper, 
    SmallDivider, 
    SectionHeader, 
    SectionSubHeader 
} from '../components/Wrapper'

const ProductPage = ({ data, location }) => {
    var showdown = require('showdown'),
        converter = new showdown.Converter();

    const [product, setProduct] = useState(null);

    const formInputs = {
        name: true,
        email: true,
        phone: true,
        message: true,
    };
    const motorFormInputs = {
        nameOrCompany: true,
        nric: true,
        dob: true,
        drivingExp: true,
        gender: true,
        carPlate: true,
        email: true,
        phone: true,
        message: true,
    };

    const getProduct = () => {
        const { id } = queryString.parse(location.search);
        let item = {};

        data.allStrapiProduct.edges.map((x) => {
            if (id === x.node.id) {
                item = x.node;
            }
        });

        return item;
    }

    useEffect(() => {
        setProduct(getProduct());
    }, [])

    return (
        <Layout>
            <SEO
                title={`All Ins Agency - ${product && product.name ? product.name : 'Product'}`}
                description={`All Ins Agency`}
            />
            {product && product.fields &&
                <Banner heading={product.name} image={data.site.siteMetadata.apiUrl + product.fields.imageUrl} />
            }
            <SectionWrapper>
                <SectionInnerWrapper>
                    <ResponsiveRow alignTop={true}>
                        <div className={`mb-4 md:mb-0 md:mr-6 md:w-2/3 ${styles.detailsContent}`}>
                            {product && product.body &&
                                <div className={styles.productBodyWrapper}
                                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(product.body) }}
                                />
                            }
                        </div>
                        <Col>
                            <div className={styles.detailsWrapper}>
                                <div className={styles.detailsInnerWrapper}>
                                    <AnchorLink href={`#enquiry`}>
                                        <Button className="w-full text-center" size="sm">Contact Us</Button>
                                    </AnchorLink>
                                    <SmallDivider></SmallDivider>
                                    <Row>
                                    <div className={styles.detailsEmailIcon}>
                                        <Icon type="fas" name="envelope"></Icon>
                                    </div>
                                    <div className={styles.detailsEmail}>
                                        getaquote@allins.com.sg
                                    </div>
                                    
                                    </Row>
                                    {/* <h3 className={styles.detailsTitle}>Share Product :</h3>
                                    <div className={styles.socialContent}>
                                        <a className={styles.postFacebook} href="#"><ShareIconButton type="fab" icon="facebook-f" /></a>
                                        <a className={styles.postGooglePlus} href="#"><ShareIconButton type="fab" icon="google-plus-g" /></a>
                                        <a className={styles.postTwitter} href="#"><ShareIconButton type="fab" icon="twitter" /></a>
                                        <a className={styles.postLinkedin} href="#"><ShareIconButton type="fab" icon="linkedin-in" /></a>
                                    </div> */}
                                </div>
                            </div>
                        </Col>
                    </ResponsiveRow>
                </SectionInnerWrapper>
            </SectionWrapper>
            <SectionWrapperGray>
                <div id="enquiry"></div>
                <SectionInnerWrapper>
                    <SectionSubHeader>QUESTIONS?</SectionSubHeader>
                    <SectionHeader>GET IN TOUCH</SectionHeader>
                    <div className="mt-6 mx-0 md:mx-4">
                        <Form inputs={product && product.motorenquiry ? motorFormInputs : formInputs} />
                    </div>
                </SectionInnerWrapper>
            </SectionWrapperGray>
        </Layout >
    )
}

export default ProductPage


export const productQuery = graphql`
    query ProductQuery {
        allStrapiProductcategory(sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    fields {
                        sortindexnum
                    }
                    title
                }
            }
        }
        allStrapiProduct(sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    id
                    name
                    category
                    icontype
                    iconname
                    numofpeople
                    location
                    payment
                    risklevel
                    support
                    motorenquiry
                    showonhomepage
                    body
                    fields {
                        sortindexnum
                        imageUrl
                    }
                }
            }
        }
        site {
            siteMetadata { 
                apiUrl
            }
        }
    }
`